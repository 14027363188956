<!-- Breadcroumbs start -->
<div class="factorian-content-block factorian-breadcroumb section-gray">
    <div class="container">
        <div class="row">
            <div class="col-md-6">
                <h2>{{translations?.products}}</h2>
                <a routerLink="/">{{translations?.home}}</a> / {{translations?.products}}
            </div>
                
            <div class="col-md-6 text-right">
                <h3></h3>
            </div>
        </div>
    </div>
</div>
    <!-- Services content start -->
    <div class="factorian-content-block">
        <div class="container">
            <div class="row wow fadeInUp" style="margin-bottom: 30px;">
                <div class="portfolio-filter font-alt align-center" >
                    <div class="col-md-8 col-md-offset-2 filter">
                        <div class="col-md-3 col-sm-6 col-xs-12 text-center" *ngFor="let item of filters">
                            <div [class.active]="set_active(item)">
                                <a href="#" [attr.data-filter]="item.id" (click)="switch_filter(item)">{{item.name}}</a>    
                            </div>
                            
                        </div>
                        
                    </div>
                </div>
            </div>
            <div class="row wow fadeInUp">
                <div id="grid" class="gallery-isotope grid-4 masonry gutter clearfix portfolio" style="min-height: 500px;">
                    <div [attr.class]="set_class(item)" *ngFor="let item of items">
                        <div class="single-service-item text-center">
                            <a [routerLink]="get_link(item)" class="single-boxed-item text-center">
                                <div class="service-item-bg" >
                                    <img src="/assets/local/product_images/thumbnails/{{item.id}}_1.jpg" alt="" (load)="images_loaded()">
                                </div>
                                <h3 class="text-center product-name">{{item.name}}</h3>
                            </a>    
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>  
    <!-- Services content end -->   