<!-- Breadcroumbs start -->
<div class="factorian-content-block factorian-breadcroumb section-gray">
        <div class="container">
            <div class="row">
                <div class="col-md-6">
                    <h2>{{translations?.about_us}}</h2>
                    <a routerLink="/">{{translations?.home}}</a> / {{translations?.about_us}}
                </div>
                  
                <div class="col-md-6 text-right">
                    <h3></h3>
                </div>
            </div>
        </div>
    </div>
        
    <!-- About content start -->
    <div class="factorian-content-block">
        <div class="container">
            
            <div class="row wow fadeInUp" id="who_are_we">
                <div class="col-md-6" >
                    
                    <div *ngFor="let section of content?.who_we_are" >
                        <div *ngIf="section.type === 'text'">
                            <h3 *ngIf="section.title">{{section.title}}</h3>
                            <p>{{section.text}}</p>
                        </div>    
                        <div *ngIf="section.type === 'bullets'">
                                <ul class="check-list">
                                    <li *ngFor="let bullet of section.bullets">
                                        {{bullet.text}}
                                    </li>
                                </ul>
                        </div>
                    </div>
                    
                    
                </div>
                
                <div class="col-md-6">
                    <img src="/assets/local/content_images/who_we_are.jpg" alt="">
                </div>
            </div>
            
            <div class="spacer-85"></div>
            
            <div class="row wow fadeInUp" id="how_it_all_started">
                <div class="col-md-4">
                    <img src="/assets/local/content_images/how_it_all_started.jpg" alt="">
                </div>
                <div class="col-md-8">
                    <div class="who-we-are-text">
                        <div *ngFor="let section of content?.how_it_all_started" >
                            <div *ngIf="section.type === 'text'">
                                <h3 *ngIf="section.title">{{section.title}}</h3>
                                <p>{{section.text}}</p>
                            </div>    
                            <div *ngIf="section.type === 'bullets'">
                                <h3 *ngIf="section.title">{{section.title}}</h3>
                                <ul class="check-list">
                                    <li *ngFor="let bullet of section.bullets">
                                        {{bullet.text}}
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            <div class="spacer-85"></div>
            
            <div class="row wow fadeInUp" id="collaborate_with_us">
                    <div class="col-md-8">
                        <div *ngFor="let section of content?.collaborate_with_us" >
                            <div *ngIf="section.type === 'text'">
                                <h3 *ngIf="section.title">{{section.title}}</h3>
                                <p>{{section.text}}</p>
                            </div>    
                            <div *ngIf="section.type === 'bullets'">
                                <ul class="check-list">
                                    <li *ngFor="let bullet of section.bullets">
                                        {{bullet.text}}
                                    </li>
                                </ul>
                            </div>
                        </div>
                        
                    </div>
                    
                    <div class="col-md-4">
                        <img src="/assets/local/content_images/collaborate_with_us.jpg" alt="">
                    </div>
                </div>
                <div class="spacer-85"></div>
                
                <div class="row wow fadeInUp" id="enterprise">
                    <div class="col-md-6">
                            <img src="/assets/local/content_images/enterprise.jpg" alt="">
                    </div>
                    <div class="col-md-6">
                        <div class="who-we-are-text">
                            <div *ngFor="let section of content?.enterprise" >
                                <div *ngIf="section.type === 'text'">
                                    <h3 *ngIf="section.title">{{section.title}}</h3>
                                    <p>{{section.text}}</p>
                                </div>    
                                <div *ngIf="section.type === 'bullets'">
                                    <ul class="check-list">
                                        <li *ngFor="let bullet of section.bullets">
                                            {{bullet.text}}
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            <div class="border-spacer-85"></div>
            
            <div class="row" id="our_team">
                <div class="col-md-6 col-md-offset-3">
                    <div class="section-title">
                        <h3>{{translations?.management}}</h3>
                    </div>
                </div>
            </div>
            
            
            <div class="row" >
                <div class="col-md-12">
                    <div class="teams-wrapper">
                        
                        <div class="single-team-item  wow fadeInUp" *ngFor="let member of team">
                            <div class="cont">
                                <img src="/assets/local/team_images/{{member.id}}.jpg" *ngIf="member.id">
                            </div>
                            <div class="col-md-12 text-center team-member-info" >
                                <h3 >{{member.full_name}}</h3>
                                <h4 class="team-position">{{member.position}}</h4>
                                <p >{{member.email}}</p>
                                <p>{{member.phone}}</p>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
    </div> 
    <!-- About content end --> 