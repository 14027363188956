<!-- Breadcroumbs start -->
<div class="factorian-content-block factorian-breadcroumb section-gray">
    <div class="container">
        <div class="row">
            <div class="col-md-6">
                <h2>{{translations?.videos}}</h2>
                <a routerLink="/">{{translations?.home}}</a> / {{translations?.videos}}
            </div>
              
            <div class="col-md-6 text-right">
                <h3></h3>
            </div>
        </div>
    </div>
</div>


<div class="container grid-container">
  <div class="row wow fadeInUp">
    <div class="portfolio-filter font-alt align-center" >
      <div class="col-md-12 filter">
        <div class="col-md-3 text-center" *ngFor="let item of filter">
          <a href="#" class="filter-item {{item.class}}" [attr.data-filter]="item.link">{{item.name}}</a>
        </div>
      </div>
    </div>
  </div>
  <div class="row wow fadeInUp videos">
    <!-- Portfolio Gallery Grid -->
      <div id="grid" class="gallery-isotope grid-4 masonry gutter clearfix portfolio" style="min-height: 400px;">
      <!-- Portfolio Item Start -->
        <div [attr.class]="set_class(item)" *ngFor="let item of videos">
          <div class="overlay">
              <div class="video-image">
                <img class="img-fullwidth" src="/assets/local/gallery/{{item.id}}.jpg" alt="image" (load)="images_loaded()">
              </div>
              <div class="video-icon">
                <a href="https://www.youtube.com/watch?v={{item.video_url}}" class="video-popup">
                  <i class="fa fa-play" aria-hidden="true"></i>
                </a>
              </div>  
          </div>
        </div> 
      </div>
     <!-- End Portfolio Gallery Grid -->
  </div>
</div>
   
   
   
   
   
   

