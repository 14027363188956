<!-- Slides start -->
<div class="homepage-slides-wrapper">
    <div class="factorian-slide-preloader-wrap">
        <div class="spinner"></div>
    </div>
    <div class="homepage-slides">
        
        <div [attr.class]="'single-slide-item slide_'+slide.id" *ngFor="let slide of slides">
            <div class="slide-item-table">
                <div class="slide-item-tablecell">
                    <div class="container">
                        <div class="row">
                            <div class="col-md-6 col-md-offset-5  col-xs-12">
                                <h2>{{slide.title}}</h2>
                                <p>{{slide.subtitle}}</p>
                                <a routerLink="{{slide.link}}" class="factorian-btn slide-btn">{{slide.link_title}}</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        
    </div>
</div>
<!-- Slides end -->


<!-- About area start -->
<div class="factorian-content-block">
    <div class="container">
        <div class="row">
            <div class="col-md-6 col-xs-12">
                <img class="wow fadeInLeft" src="/assets/local/content_images/who_we_are.jpg" alt="">
            </div>
            
            <div class="col-md-6 col-xs-12">
                <div class="who-we-are-text wow fadeInUp">
                        <div *ngFor="let section of about_content?.who_we_are" >
                            <div *ngIf="section.type === 'text'">
                                <h3 *ngIf="section.title">{{section.title}}</h3>
                                <p>{{section.text}}</p>
                            </div>    
                            <div *ngIf="section.type === 'bullets'">
                                    <ul class="check-list">
                                        <li *ngFor="let bullet of section.bullets">
                                            {{bullet.text}}
                                        </li>
                                    </ul>
                            </div>
                        </div>
                    <br>
                    <a routerLink="/about/who_are_we" class="service-readmore-btn">{{translations?.see_more_about_us}} <i class="fa fa-long-arrow-right"></i></a>
                </div>
            </div>
            
            
        </div>
        
        <div class="row">
            <div class="col-sm-4">
                <div class="single-promo-item wow fadeInUp">
                    <i class="fa fa-check"></i>
                    <h3>{{translations?.best_quality_main_page}}</h3>
                    <p>{{translations?.best_quality_text_main_page}}</p>
                </div>
            </div>
            <div class="col-sm-4">
                <div class="single-promo-item wow fadeInUp">
                    <i class="fa fa-check"></i>
                    <h3>{{translations?.experience_main_page}}</h3>
                    <p>{{translations?.experience_text_main_page}}</p>
                </div>
            </div>
            <div class="col-sm-4">
                <div class="single-promo-item wow fadeInUp">
                    <i class="fa fa-check"></i>
                    <h3>{{translations?.research_main_page}}</h3>
                    <p>{{translations?.research_text_main_page}}</p>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- About area end -->  


<!-- Works start -->
<div class="factorian-content-block">
    <div class="container">
        <div class="row">
            <div class="col-md-6 col-md-offset-3 col-xs-12">
                <div class="section-title wow fadeInUp">
                    <h3>{{translations.see_our_products_main_page}}</h3>
                </div>
            </div>
        </div>
        
        <div class="row">
                <div class="col-md-4 col-sm-6 col-xs-12 catalog-item gallery-item" *ngFor="let item of items">
                        <div class="single-service-item text-center">
                            <a routerLink="{{item.link}}" class="single-boxed-item text-center">
                                <div class="service-item-bg" >
                                    <img src="/assets/local/categories/{{item.id}}.jpg" alt="">
                                </div>
                                <h3 class="text-center product-name">{{item.title}}</h3>
                            </a>    
                        </div>
                    </div>
            
        </div>
        
        <div class="row">
            <div class="project-readmore-wrap text-center wow fadeInUp">
                <a routerLink="/products/all" class="service-readmore-btn">{{translations?.view_all_products}} <i class="fa fa-long-arrow-right"></i></a>
            </div>
        </div>
    </div>
</div> 
<!-- Works end --> 

<!-- CTA start -->
<div class="factorian-cta-area">
    <div class="container">
        <div class="row">
            <div class="col-md-9">
                <h2 class=" wow fadeInLeft">{{translations?.collaborate_main_page}}</h2>
            </div>
            <div class="col-md-3 text-right">
                <a routerLink="/contact" class="factorian-btn cta-btn  wow fadeInRight">{{translations?.contact_us}}</a>
            </div>
        </div>
    </div>
</div>
<!-- CTA end -->

<div class="factorian-content-block">
        <div class="container">
            <div class="row">
                <div class="col-md-6 col-md-offset-3  col-xs-12">
                    <div class="section-title wow fadeInUp">
                        <h3>{{translations?.see_latest_news_main_page}}</h3>
                    </div>
                </div>
            </div>
            
            <div class="row">
                <div class="col-sm-4 news-item" *ngFor="let item of limited_news">
                    <a routerLink="/media/news/{{item.id}}" class="single-boxed-item wow fadeInUp">
                        <div class="boxed-preview">
                            <img src="/assets/local/news_images/{{item.id}}.jpg" alt="">
                        </div>
                        <h4>{{item.name}}</h4>
                        {{item.date_full_formatted}}
                    </a>
                </div>
            </div>
            <div class="row">
                <div class="project-readmore-wrap text-center wow fadeInUp">
                    <a routerLink="/media/news" class="service-readmore-btn">{{translations?.view_all_news}} <i class="fa fa-long-arrow-right"></i></a>
                </div>
            </div>
        </div>
    </div>
<!-- Why choose us start -->
<div class="factorian-content-block section-gray-video">
    <div class="container">
        <div class="row">
            <div class="col-md-6 wow fadeInUp  col-xs-12">
                <div *ngFor="let section of about_content?.collaborate_with_us_main_page" >
                    <div *ngIf="section.type === 'text'">
                        <h3 *ngIf="section.title">{{section.title}}</h3>
                        <p>{{section.text}}</p>
                    </div>    
                    <div *ngIf="section.type === 'bullets'">
                            <ul class="check-list">
                                <li *ngFor="let bullet of section.bullets">
                                    {{bullet.text}}
                                </li>
                            </ul>
                    </div>
                </div>
            </div>
            
            <div class="col-md-6 wow fadeInUp  col-xs-12">
                <div class="padding-left-30">
                    <!--
                    <div class="embed-responsive embed-responsive-16by9">
                        <iframe  class="embed-responsive-item" width="816" height="459" [attr.src]="video_link" allowfullscreen></iframe>
                    </div>
                    -->
                    <img src="/assets/local/content_images/collaborate_with_us.jpg" alt="">
                </div>
            </div>
        </div>
    </div>
</div> 
<!-- Why choose us end -->  





<!-- Testimonials start -->
<div class="factorian-content-block testimonial-area">
    <div class="container">
        <div class="row">
            <div class="col-sm-4" *ngFor="let item of testimonials">
                <div class="single-testimonial-item wow fadeInUp">
                    <div class="testimonial-content">
                        <p><i class="fa fa-quote-left"></i>
                        <span [innerHTML]="item.text"></span> </p>
                    </div>
                    
                    <h4>{{item.name}} <span>{{item.title}}</span></h4>
                </div>
            </div>
        </div>
    </div>
</div> 
<!-- Testimonials end --> 

<!-- CTA start -->
<div class="factorian-cta-area">
    <div class="container">
        <div class="row">
            <div class="col-md-9">
                <h2 class=" wow fadeInLeft">{{translations?.collaborate_main_page}}</h2>
            </div>
            <div class="col-md-3 text-right">
                <a routerLink="/contact" class="factorian-btn cta-btn  wow fadeInRight">{{translations?.contact_us}}</a>
            </div>
        </div>
    </div>
</div> 
<!-- CTA end -->


<!-- Blog start -->
<div class="factorian-content-block">
    <div class="container">
        <div class="row">
            <div class="col-md-6 col-md-offset-3  col-xs-12">
                <div class="section-title wow fadeInUp">
                    <h3>{{translations?.see_latest_blogs_main_page}}</h3>
                </div>
            </div>
        </div>
        
        <div class="row">
            <div class="col-sm-4 news-item" *ngFor="let item of limited_blog">
                <a routerLink="/media/blog/{{item.id}}" class="single-boxed-item wow fadeInUp">
                    <div class="boxed-preview">
                        <img src="/assets/local/blog_images/{{item.id}}.jpg" alt="">
                    </div>
                    <h4>{{item.name}}</h4>
                    {{item.date_full_formatted}}
                </a>
            </div>
        </div>
        <div class="row">
            <div class="project-readmore-wrap text-center wow fadeInUp">
                <a routerLink="/media/blog" class="service-readmore-btn">{{translations?.view_all}} <i class="fa fa-long-arrow-right"></i></a>
            </div>
        </div>
    </div>
</div>
<!-- Blog end -->            