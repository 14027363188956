<!-- Breadcroumbs start -->
<div class="factorian-content-block factorian-breadcroumb section-gray">
    <div class="container">
        <div class="row">
            <div class="col-md-6">
                <h2>{{translations?.career}}</h2>
                <a routerLink="/">{{translations?.home}}</a> / {{translations?.career}}
            </div>
              
            <div class="col-md-6 text-right">
                <h3></h3>
            </div>
        </div>
    </div>
</div>

<!-- About content start -->
<div class="factorian-content-block">
    <div class="container">
        <div class="row wow fadeInUp" id="hr_policy">
            <div class="col-md-6">
                <div *ngFor="let section of content?.hr_policy" >
                    <div *ngIf="section.type === 'text'">
                        <h3 *ngIf="section.title">{{section.title}}</h3>
                        <p>{{section.text}}</p>
                    </div>    
                    <div *ngIf="section.type === 'bullets'">
                        <ul class="check-list">
                            <li *ngFor="let bullet of section.bullets">
                                {{bullet.text}}
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            
            <div class="col-md-6">
                <img src="/assets/local/content_images/hr_policy.jpg" alt="">
            </div>
        </div>
        <div class="spacer-85"></div>
        
        <div class="row wow fadeInUp" id="working_environment">
            <div class="col-md-6">
                <img src="/assets/local/content_images/working_environment.jpg" alt="">
            </div>
            
            <div class="col-md-6">
                <div class="who-we-are-text">
                    <div *ngFor="let section of content?.working_environment" >
                        <div *ngIf="section.type === 'text'">
                            <h3 *ngIf="section.title">{{section.title}}</h3>
                            <p>{{section.text}}</p>
                        </div>    
                        <div *ngIf="section.type === 'bullets'">
                            <ul class="check-list">
                                <li *ngFor="let bullet of section.bullets">
                                    {{bullet.text}}
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="spacer-85"></div>

        <div class="row wow fadeInUp" id="career_development">
            <div class="col-md-6">
                <div *ngFor="let section of content?.career_development" >
                    <div *ngIf="section.type === 'text'">
                        <h3 *ngIf="section.title">{{section.title}}</h3>
                        <p>{{section.text}}</p>
                    </div>    
                    <div *ngIf="section.type === 'bullets'">
                        <ul class="check-list">
                            <li *ngFor="let bullet of section.bullets">
                                {{bullet.text}}
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            
            <div class="col-md-6">
                <img src="/assets/local/content_images/career_development.jpg" alt="">
            </div>
        </div>
        <div class="spacer-85"></div>
        
        <div class="row wow fadeInUp" id="social_responsibility">
            <div class="col-md-6">
                <img src="/assets/local/content_images/social_responsibility.jpg" alt="">
            </div>
            
            <div class="col-md-6">
                <div class="who-we-are-text">
                    <div *ngFor="let section of content?.social_responsibility" >
                        <div *ngIf="section.type === 'text'">
                            <h3 *ngIf="section.title">{{section.title}}</h3>
                            <p>{{section.text}}</p>
                        </div>    
                        <div *ngIf="section.type === 'bullets'">
                            <ul class="check-list">
                                <li *ngFor="let bullet of section.bullets">
                                    {{bullet.text}}
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="spacer-85"></div>
        <div class="row wow fadeInUp" id="out_of_work">
                <div class="col-md-6">
                    <div *ngFor="let section of content?.out_of_work" >
                        <div *ngIf="section.type === 'text'">
                            <h3 *ngIf="section.title">{{section.title}}</h3>
                            <p>{{section.text}}</p>
                        </div>    
                        <div *ngIf="section.type === 'bullets'">
                            <ul class="check-list">
                                <li *ngFor="let bullet of section.bullets">
                                    {{bullet.text}}
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                
                <div class="col-md-6">
                    <img src="/assets/local/content_images/out_of_work.jpg" alt="">
                </div>
            </div>
    </div>
</div>