<!-- Breadcroumbs start -->
<div class="factorian-content-block factorian-breadcroumb section-gray">
        <div class="container">
            <div class="row">
                <div class="col-md-6">
                    <h2>Events</h2>
                    <a routerLink="/">Home</a> / Events
                </div>
                  
                <div class="col-md-6 text-right">
                    <h3></h3>
                </div>
            </div>
        </div>
    </div>
    
    
    <!-- blog content start -->
    <div class="factorian-content-block">
        <div class="container">
            <div class="row">
                <div class="col-md-8">
                    <div class="row">
                        <div class="col-sm-6">
                            <a href="/events/id" class="single-boxed-item wow fadeInUp">
                                <div class="boxed-preview blog-bg-1"></div>
                                <h4>How I started my startup with only $100 and got success.</h4>
                                25th June, 2016
                            </a>
                        </div>
                        <div class="col-sm-6">
                            <a href="/events/id" class="single-boxed-item wow fadeInUp">
                                <div class="boxed-preview blog-bg-2"></div>
                                <h4>Lorem ipsum dolor sit amet, consectetur adipisicing elit.</h4>
                                25th June, 2016
                            </a>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-6">
                            <a href="/events/id" class="single-boxed-item wow fadeInUp">
                                <div class="boxed-preview blog-bg-3"></div>
                                <h4>Lorem ipsum dolor sit amet, consectetur.</h4>
                                25th June, 2016
                            </a>
                        </div>
                        <div class="col-sm-6">
                            <a href="/events/id" class="single-boxed-item wow fadeInUp">
                                <div class="boxed-preview work-bg-1"></div>
                                <h4>Lorem ipsum dolor sit amet, consectetur adipisicing.</h4>
                                25th June, 2016
                            </a>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-6">
                            <a href="single-post.html" class="single-boxed-item wow fadeInUp">
                                <div class="boxed-preview work-bg-2"></div>
                                <h4>Lorem ipsum dolor sit amet, consectetur adipisicing elit.</h4>
                                25th June, 2016
                            </a>
                        </div>
                        <div class="col-sm-6">
                            <a href="single-post.html" class="single-boxed-item wow fadeInUp">
                                <div class="boxed-preview work-bg-3"></div>
                                <h4>Lorem ipsum dolor sit amet, consectetur.</h4>
                                25th June, 2016
                            </a>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-6">
                            <a href="single-post.html" class="single-boxed-item wow fadeInUp">
                                <div class="boxed-preview work-bg-4"></div>
                                <h4>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quidem, quasi.</h4>
                                25th June, 2016
                            </a>
                        </div>
                        <div class="col-sm-6">
                            <a href="single-post.html" class="single-boxed-item wow fadeInUp">
                                <div class="boxed-preview work-bg-5"></div>
                                <h4>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Incidunt!</h4>
                                25th June, 2016
                            </a>
                        </div>
                    </div>
                    
                    
                </div>
                <div class="col-md-4">
                    <div class="widget">
                        <form class="search-form" action="blog.html">
                            <input type="search" placeholder="Type to search here...">
                            <button type="submit"><i class="fa fa-search"></i></button>
                        </form>
                    </div>
                    
                    <div class="widget recent_posts">
                        <h3 class="widget-title">Recent posts</h3>
                        <ul>
                            <li><a href="">How to Create a Countdown Timer With a Full Screen Background Video. <span>21st Nov, 2016</span></a></li>
                            <li><a href="">How to Re-Order Your Blog Posts (with 2 Methods) <span>21st Nov, 2016</span></a></li>
                            <li><a href="">How to Create a Countdown Timer With a Full Screen Background Video. <span>21st Nov, 2016</span></a></li>
                            <li><a href="">How to Re-Order Your Blog Posts (with 2 Methods) <span>21st Nov, 2016</span></a></li>
                        </ul>
                    </div>
                    
                    <div class="widget category_list">
                        <h3 class="widget-title">Category</h3>
                        <ul>
                            <li><a href="">Technology</a></li>
                            <li><a href="">Business</a></li>
                            <li><a href="">Education</a></li>
                            <li><a href="">Movies</a></li>
                            <li><a href="">Travel</a></li>
                            <li><a href="">Marketing</a></li>
                            <li><a href="">Development</a></li>
                            <li><a href="">Design</a></li>
                            <li><a href="">Sports</a></li>
                            <li><a href="">Music</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>  
    <!-- Blog content end -->   