<!-- Breadcroumbs start -->
<div class="factorian-content-block factorian-breadcroumb section-gray">
    <div class="container">
        <div class="row">
            <div class="col-md-6">
                <h2>{{data?.title}}</h2>
                <a routerLink="/">{{translations?.home}}</a> / <a routerLink="/tenders" >{{translations?.tenders}}</a> / {{data?.title}}
            </div>
              
            
        </div>
    </div>
</div> 
<!-- Breadcroumbs end -->

<!-- Contact area start -->
 <div class="factorian-content-block" >
    <div class="container">
        <div class="row wow fadeInUp">
            <div *ngFor="let item of data?.sections">
              <div *ngIf="item.type=='text'"  class="col-md-12">
                <h4>{{item.title}}</h4>
                <p>{{item.text}}</p>    
              </div>
              <div *ngIf="item.type=='bullets'" class="col-md-12">
                <h4>{{item.title}}</h4>
                <ul class="check-list">
                  <li *ngFor="let bullet of item.bullet_points">
                    {{bullet.bullet}}
                  </li>
                </ul>    
              </div>
              <div *ngIf="item.type=='image'"  class="col-md-12">
                  <h4>{{item.title}}</h4>
                <div class="col-md-6" style="margin-top: 20px;">
                    <img src="{{item.image_link}}" alt="image">
                    <p ><small>{{item.text}}</small></p>
                </div>      
              </div>
              <div *ngIf="item.type=='file_link'"  class="col-md-12">
                  <h4>
                    <a href="{{item.pdf_link}}" target="_blank">{{item.title}}</a>
                  </h4>                  
              </div>
              <div *ngIf="item.type=='link'"  class="col-md-12">
                  <h4>
                    <a href="{{item.url}}" target="_blank">{{item.title}}</a>
                  </h4>                  
              </div>
              <div *ngIf="item.type=='info'"  class="col-md-12" style="margin-top: 40px; margin-bottom: 40px;">
                  <div class="col-md-1">
                    <div class="outer-info">
                        <i class="fa fa-exclamation" aria-hidden="true"></i>
                    </div>
                  </div>
                  <div class="col-md-11">
                      <div>
                          <p>{{item.text}}</p>
                      </div>
                    </div>                  
              </div>
            </div>
        </div>
        <div class="spacer-45"></div>
        <div class="row">
            <div class="project-readmore-wrap text-center wow fadeInUp">
                <a class="service-readmore-btn" (click)="show_application()"> {{translations?.fill_the_form}}</a>
            </div>
        </div>
  </div>
</div>

<div class="modal fade" id="result_modal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" >
    <div class="modal-dialog modal-md" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
          <h4 class="modal-title" id="myModalLabel">{{translations.warning}}</h4>
        </div>
        <div class="modal-body item-body">
          <h4 id="modal-message">
            {{translations?.no_access}}
          </h4>
          
        <div class="modal-footer">
          <button type="button" class="btn btn-default" data-dismiss="modal" >OK</button>
          
        </div>
      </div>
    </div>
  </div>
</div>
  <div class="modal fade" id="form_modal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" >
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
            <h4 class="modal-title" id="myModalLabel">{{translations?.fill_the_form}}</h4>
          </div>
          <div class="modal-body item-body">
              <div class="get-quote-form">
                  
                  <form [formGroup]="tender_form" class="form-modern" novalidate #form enctype="multipart/form-data">
                      <input [(ngModel)]="token" type="hidden" value="" name="csrf_token" formControlName="csrf_token">
                      
                      <div class="form-group form-group-modern">
                          <label for="name">{{translations.name_company}}</label> 
                          <input name="name" type="text" class="form-control" formControlName="name">
                          <div class="errorMessage" *ngIf="tender_form.controls.name.errors?.required && tried_to_proceed">
                              <small>
                                    {{translations?.name_is_required}}
                              </small>
                          </div>
                      </div>
                      <div class="form-group form-group-modern">
                          <label for="email">{{translations?.email}}</label> 
                          <input name="email" type="email" class="form-control" pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$" formControlName="email">
                          <div class="errorMessage" *ngIf="tender_form.controls.email.errors?.required && tried_to_proceed">
                              <small>
                                    {{translations?.email_is_required}}
                              </small>
                          </div>
                          <div class="errorMessage" *ngIf="tender_form.controls.email.errors?.pattern && tender_form.controls.email.dirty && tried_to_proceed">
                              <small>
                                    {{translations?.email_format_is_wrong}}
                              </small>
                          </div>
                      </div>
                      <div class="form-group form-group-modern">
                          <label for="phone">{{translations?.phone}}</label> 
                          <input name="phone" type="text" class="form-control" formControlName="phone">
                      </div>
                      <div class="form-group form-group-modern">
                          <label for="message">{{translations.message}}</label> 
                          <textarea name="message" id="message" cols="30" rows="10" class="form-control" formControlName="message"></textarea>
                          <div class="errorMessage" *ngIf="tender_form.controls.message.errors?.required && tried_to_proceed">
                              <small>
                                    {{translations?.message_is_required}}
                              </small>
                          </div>
                      </div>
                      <div class="form-group form-group-modern">
                          <label for="file">{{translations?.file}} ({{translations?.only_pdf_files}})</label> 
                          <input name="file" type="file" class="form-control" formControlName="file" #fileInput>
                          <div class="errorMessage" *ngIf="tender_form.controls.file.errors?.required && tried_to_proceed">
                              <small>
                                    {{translations?.file_is_required}}
                              </small>
                          </div>
                          <div class="errorMessage" *ngIf="format_error && tried_to_proceed">
                              <small>
                                    {{translations?.pdf_format_error}}
                              </small>
                          </div>
                      </div>
                      
                  </form>
              </div>
            
          <div class="modal-footer">
            <button type="button" class="btn btn-default" data-dismiss="modal">{{translations?.cancel}}</button>
            <button type="button" class="btn btn-default" (click)="submit_form(tender_form, form)" >{{translations?.submit}}</button>
            <h6 *ngIf="tender_request_is_sent">{{translations?.tender_request_is_sent}} </h6>
          </div>
        </div>
      </div>
    </div>
  </div>