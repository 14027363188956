<!-- Breadcroumbs start -->
<div class="factorian-content-block factorian-breadcroumb section-gray">
    <div class="container">
        <div class="row">
            <div class="col-md-6">
                <h2>{{translations?.login_page}}</h2>
                <a routerLink="/">{{translations?.home}}</a> / {{translations?.login_page}}
            </div>
                
            <div class="col-md-6 text-right">
                <h3></h3>
            </div>
        </div>
    </div>
</div>
<!-- Contact area start -->
 <div class="factorian-content-block">
    <div class="container">
        <div class="row wow fadeInUp" style="margin-bottom: 30px;">
            <div class="col-md-6 col-md-offset-3">
                <p [innerHTML]="translations?.goto_contact_login"></p>
            </div>
        </div>
        <div class="row wow fadeInUp">
            
            
            <div class="col-md-6 col-md-offset-3">
                <div class="get-quote-form">
                    
                    <form [formGroup]="login_form" class="form-modern" novalidate #form>
                        <input [(ngModel)]="csrf_token" type="hidden" value="" name="csrf_token" formControlName="csrf_token">
                        
                        <div class="form-group form-group-modern">
                            <label for="email">{{translations?.email}}</label> 
                            <input name="email" type="email" class="form-control" pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$" formControlName="email">
                            <div class="errorMessage" *ngIf="login_form.controls.email.errors?.required && tried_to_proceed">
                                <small>
                                    {{translations?.email_is_required}}
                                </small>
                            </div>
                            <div class="errorMessage" *ngIf="login_form.controls.email.errors?.pattern && login_form.controls.email.dirty && tried_to_proceed">
                                <small>
                                        {{translations?.email_format_is_wrong}}
                                </small>
                            </div>
                        </div>
                        <div class="form-group form-group-modern">
                            <label for="password">{{translations?.password}}</label> 
                            <input name="password" type="password" class="form-control" formControlName="password">
                            <div class="errorMessage password-error">
                                    <small>
                                            {{translations?.password_or_username_incorrect}}
                                    </small>
                                </div>
                            <div class="errorMessage" *ngIf="login_form.controls.password.errors?.required && tried_to_proceed">
                                <small>
                                        {{translations?.password_is_required}}
                                </small>
                            </div>
                        </div>
                        <div>
                            <input type="submit" value="{{translations?.login}}" (click)="submit_form(login_form, form)">
                        </div>
                    </form>
                </div>
            </div>
        </div>
  </div>
</div>
