<!-- Breadcroumbs start -->
<div class="factorian-content-block factorian-breadcroumb section-gray">
    <div class="container">
        <div class="row">
            <div class="col-md-6">
                <h2>{{translations.tenders}}</h2>
                <a routerLink="/">{{translations.home}}</a> / {{translations.tenders}}
            </div>
              
            <div class="col-md-6 text-right">
                <h3></h3>
            </div>
        </div>
    </div>
</div>

<!-- Contact area start -->
 <div class="factorian-content-block" >
    <div class="container">
        <div class="row wow fadeInUp">
            
            
            <div class="col-md-10 col-md-offset-1">
                <table class="table" > 
                  <thead>
                    <tr>
                      <th class="title">{{translations.title}}</th>
                      <th class="title">{{translations.category}}</th>
                      <th>{{translations.date}}</th>
                      
                    </tr>
                  </thead>
                  <tbody> 
                    <tr *ngFor="let item of data">
                      <td>
                        <a routerLink="/tender/{{item.id}}">
                            {{item.title}}
                        </a>
                      </td>
                      <td>{{item.category}}</td>
                      <td>{{item.date}}</td>
                    </tr>
                  </tbody>
                </table>
            </div>
        </div>
  </div>
</div>

