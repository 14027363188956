<!-- Breadcroumbs start -->
<div class="factorian-content-block factorian-breadcroumb section-gray">
  <div class="container">
      <div class="row">
          <div class="col-md-6">
              <h2>{{translations?.search}}</h2>
              <a routerLink="/">{{translations?.home}}</a> / {{translations?.search}}
          </div>
              
          <div class="col-md-6 text-right">
              <h3></h3>
          </div>
      </div>
  </div>
</div>
  <!-- Services content start -->
  <div class="factorian-content-block">
      <div class="container">
          <div class="row wow fadeInUp" *ngIf="items.length == 0">
              <h3>{{translations?.no_results}}</h3>
          </div>
          <div class="row wow fadeInUp" *ngIf="items.length">
              <div id="grid" class="gallery-isotope grid-4 masonry gutter clearfix portfolio" style="min-height: 500px;">
                  <div class="col-md-4 col-sm-6 col-xs-12 catalog-item gallery-item" *ngFor="let item of items">
                      <div class="single-service-item text-center">
                          <a [routerLink]="get_link(item)" class="single-boxed-item text-center">
                              <div class="service-item-bg" >
                                  <img src="/assets/local/product_images/thumbnails/{{item.id}}_1.jpg" alt="">
                              </div>
                              <h3 class="text-center product-name">{{item.name}}</h3>
                          </a>    
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>  
  <!-- Services content end -->   