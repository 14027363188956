 <!-- Contact area start -->
 <div class="factorian-content-block" >
    <div class="container">
        <div class="row wow fadeInUp">
            
            
            <div class="col-md-10 col-md-offset-1">
                <table class="table" *ngIf="show_content()==true"> 
                  <thead>
                    <tr>
                      <th class="title">Title</th>
                      <th>Date</th>
                      <th>PDF</th>
                      <th>XLS</th>
                    </tr>
                  </thead>
                  <tbody> 
                    <tr *ngFor="let item of data">
                      <td>{{item.title}}</td>
                      <td>{{item.date}}</td>
                      <td>
                          <a href="/assets/local/pdf_files/{{item.id}}.pdf" target="_blank">
                        <button>
                          PDF
                        </button></a>

                        </td>
                        <td>
                            <a href="/assets/local/xls_files/{{item.id}}.xls" target="_blank">
                          <button>
                            EXCEL
                          </button></a>
  
                          </td>
                    </tr>
                  </tbody>
                </table>
            </div>
        </div>
  </div>
</div>

<div class="modal fade" id="result_modal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" >
    <div class="modal-dialog modal-md" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
          <h4 class="modal-title" id="myModalLabel">{{translations.warning}}</h4>
        </div>
        <div class="modal-body item-body">
          <h4 id="modal-message">
              {{translations.no_access}}
          </h4>
          
        <div class="modal-footer">
          <button type="button" class="btn btn-default" (click)="dismiss_modal()" >OK</button>
          
        </div>
      </div>
    </div>
  </div>