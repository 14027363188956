<!-- Breadcroumbs start -->
<div class="factorian-content-block factorian-breadcroumb section-gray">
    <div class="container">
        <div class="row">
            <div class="col-md-6">
                <h2>{{news?.name}}</h2>
                <a routerLink="/">{{translations?.home}}</a> /
                <a routerLink="/media/news">{{translations?.news}}</a> / {{news?.name}}
            </div>
              
            <div class="col-md-6 text-right">
                <h3></h3>
            </div>
        </div>
    </div>
</div>
<!-- blog content start -->
<div class="factorian-content-block">
    <div class="container">
        <div class="row">
            <div class="col-md-8">
                <img src="/assets/local/news_images/{{news?.id}}.jpg" alt="" *ngIf="news?.id">
                <h3>{{news?.name}}</h3>
                <h4>{{news?.date_full_formatted}}</h4>
                <div [innerHTML]="news?.text"></div>
                    
            </div>
            <div class="col-md-4">
                <div class="widget recent_posts">
                    <h3 class="widget-title">{{translations?.recent_news}}</h3>
                    <ul>
                        <li *ngFor="let item of list">
                          <a routerLink="/media/news/{{item.id}}">
                            {{item.name}}
                            <span>{{item.date_full_formatted}}</span>
                          </a>
                        </li>
                        
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>  
