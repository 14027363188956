<!-- Breadcroumbs start -->
<div class="factorian-content-block factorian-breadcroumb section-gray">
    <div class="container">
        <div class="row">
            <div class="col-md-6">
                <h2>{{translations?.foundation_tanadgoma}}</h2>
                <a routerLink="/">{{translations?.home}}</a> / {{translations?.foundation_tanadgoma}}
            </div>
              
            <div class="col-md-6 text-right hidden-xs">
                <img [attr.src]="get_image_link()" alt="" style="width: 110px; margin-right: 50px;">
            </div>
        </div>
    </div>
</div>

<!-- About content start -->
<div class="factorian-content-block">
    <div class="container">
        <div class="row wow fadeInUp" id="about_foundation">
            <div class="col-md-6">
                <div *ngFor="let section of content?.about_fund" >
                    <div *ngIf="section.type === 'text'">
                        <h3 *ngIf="section.title">{{section.title}}</h3>
                        <p>{{section.text}}</p>
                    </div>    
                    <div *ngIf="section.type === 'bullets'">
                        <ul class="check-list">
                            <li *ngFor="let bullet of section.bullets">
                                {{bullet.text}}
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            
            <div class="col-md-6">
                <img src="/assets/local/content_images/about_fund.jpg" alt="">
            </div>
        </div>
        <div class="spacer-85"></div>
      </div>
</div>

<div class="factorian-content-block" *ngIf="tanadgoma_news.length > 0">
    <div class="container text-center">
        <h1>{{translations?.news}}</h1>
        <div class="row wow fadeInUp" id="about_foundation">
            <div id="grid" class="gallery-isotope grid-4 masonry gutter clearfix portfolio" style="min-height: 400px;">
                <div [attr.class]="set_class(item)" *ngFor="let item of tanadgoma_news">
                    <a routerLink="/media/news/{{item.id}}" class="single-boxed-item wow fadeInUp">
                        <div class="boxed-preview">
                            <img src="/assets/local/news_images/{{item.id}}.jpg" alt="" (load)="images_loaded()">
                        </div>
                        <h4>{{item.name}}</h4>
                        {{item.date_full_formatted}}
                    </a>
                </div>
            </div>
        </div>
</div>

