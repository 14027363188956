<!-- Preloader start -->
<div class="factorian-site-preloader-wrap">
  <div class="spinner-2"></div>
</div>
<!-- Preloader end -->

<!-- Header top area start -->
<div class="header-top-area wow fadeIn">
  <div class="container">
    <div class="row">
      <div class="col-md-6">
        <a href="mailto:info@azot.ge" class="company_email">
          <i class="fa fa-envelope"></i> {{ translations?.company_email }}</a
        >
        <span class="seprator">|</span>
        <a href="tel:{{ translations?.company_phone }}" class="company-phone">
          <i class="fa fa-phone"></i> {{ translations?.company_phone }}</a
        >
        <span class="seprator hidden-xs">|</span>
        <a
          href="{{ translations?.facebook_link }}"
          target="_blank"
          class="soc-net hidden-xs"
        >
          <i class="fa fa-facebook" style="color: white;"></i>
        </a>
        <a
          href="{{ translations?.linkedin_link }}"
          target="_blank"
          class="soc-net hidden-xs"
        >
          <i class="fa fa-linkedin" style="color: white;"></i>
        </a>
      </div>
      <div class="col-md-6 text-right">
        <li *ngIf="user_is_logged === true" class="menu">
          <button (click)="logout_user()" class="logout">
            {{ translations?.logout }}
          </button>
        </li>
        <li class="menu" *ngIf="current_page != private_page">
          <a routerLink="/private-page">
            <button class="logout">{{ translations?.finances }}</button>
          </a>
        </li>
        <li *ngFor="let item of languages" class="menu">
          <a id="{{ item.id }}" (click)="set_language(item.id)">{{
            item.name
          }}</a>
        </li>
      </div>
    </div>
  </div>
</div>
<!-- Header top area end -->

<!-- Mainmenu start -->
<div class="mainmenu-area">
  <div class="container">
    <div class="row">
      <div class="col-md-1">
        <div class="logo">
          <a routerLink="/">
            <img
              *ngIf="current_lang"
              src="/assets/img/logo_{{ current_lang }}.png"
              alt=""
            />
          </a>
        </div>

        <div class="responsive-menu-wrap">
          <div class="slicknav_menu">
            <a
              aria-haspopup="true"
              role="button"
              tabindex="0"
              class="slicknav_btn slicknav_collapsed"
              style="outline: none;"
              (click)="show_menu()"
            >
              <span class="slicknav_menutxt">{{ translations?.menu }}</span>
              <span class="slicknav_icon">
                <span class="slicknav_icon-bar"></span>
                <span class="slicknav_icon-bar"></span>
                <span class="slicknav_icon-bar"></span>
              </span>
            </a>
            <a
              aria-haspopup="true"
              role="button"
              tabindex="0"
              class="slicknav_btn slicknav_collapsed"
              style="
                background: rgb(185, 50, 37) !important;
                outline: none;
                margin-right: 10px;
              "
              (click)="redirect_to_order()"
            >
              <span class="slicknav_menutxt">{{
                translations?.order_short
              }}</span>
            </a>
            <ul class="slicknav_nav" aria-hidden="true" role="menu">
              <li
                [class.current_page_item]="set_current_page(item)"
                *ngFor="let item of menu_items"
              >
                <a
                  routerLink="{{ item.link }}"
                  role="menuitem"
                  tabindex="-1"
                  *ngIf="item_children_length(item) == 0"
                >
                  {{ item.name }}
                </a>
                <a
                  (click)="show_submenu(item)"
                  aria-haspopup="true"
                  tabindex="0"
                  class="slicknav_item slicknav_row"
                  style="outline: none;"
                  role="menuitem"
                  tabindex="-1"
                  *ngIf="item_children_length(item) > 0"
                >
                  <a tabindex="0">{{ item.name }}</a>
                  <span class="slicknav_arrow">{{ item.symbol }}</span>
                  <ul
                    role="menu"
                    aria-hidden="false"
                    class="submenu"
                    [style.height]="submenu_height(item)"
                  >
                    <li
                      *ngFor="let subitem of item.children"
                      [class.current_page_item]="
                        current_menu_item.link === subitem.link
                      "
                    >
                      <a
                        routerLink="{{ subitem.link }}"
                        role="menuitem"
                        tabindex="0"
                      >
                        {{ subitem.name }}
                      </a>
                    </li>
                  </ul>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="col-md-11">
        <div class="mainmenu">
          <ul id="navigation">
            <li
              [class.current_page_item]="set_current_page(item)"
              *ngFor="let item of menu_items"
            >
              <a
                routerLink="{{ item.link }}"
                *ngIf="!item_children_length(item)"
              >
                {{ item.name }}
              </a>
              <a *ngIf="item_children_length(item)">
                {{ item.name }}
                <ul>
                  <li
                    *ngFor="let subitem of item.children"
                    [class.current_page_item]="
                      current_menu_item.link === subitem.link
                    "
                  >
                    <a routerLink="{{ subitem.link }}">{{ subitem.name }}</a>
                  </li>
                </ul>
              </a>
            </li>
            <li
              class="current_page_item"
              style="background-color: rgb(185, 50, 37) !important;"
            >
              <a
                href="http://form.rustaviazot.ge"
                style="background-color: rgb(185, 50, 37) !important;"
                >{{ translations.place_order }}</a
              >
            </li>
          </ul>
        </div>

        <div class="header-search-bar">
          <form action="/">
            <input
              id="search"
              type="search"
              placeholder="{{ translations?.keywords }} ..."
              (focusin)="show_button()"
              (focusout)="hide_button()"
            />
          </form>
          <input
            type="submit"
            class="search-button"
            value="{{ translations?.search }}"
            (click)="go_to_search()"
          />
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Mainmenu end -->

<router-outlet></router-outlet>

<!-- Footer start -->
<footer class="site-footer wow fadeIn">
  <div class="footer-top-area">
    <div class="container">
      <div class="row">
        <div class="col-md-3 col-sm-6">
          <div class="footer-wid">
            <img
              *ngIf="current_lang"
              src="/assets/img/logo_{{ current_lang }}_white.svg"
              alt=""
              style="max-width: 160px;"
            />
          </div>
        </div>
        <div class="col-md-3 col-sm-6">
          <div class="footer-wid">
            <h3 class="footer-wid-title">{{ translations?.quick_links }}</h3>
            <ul>
              <li>
                <a routerLink="/">{{ translations?.home }}</a>
              </li>
              <li>
                <a routerLink="/about/who_are_we">{{
                  translations?.about_us
                }}</a>
              </li>
              <li>
                <a routerLink="/products">{{ translations?.products }}</a>
              </li>
              <li>
                <a routerLink="/media/blog">{{ translations?.blog }}</a>
              </li>
              <li>
                <a routerLink="/private-page">{{ translations?.finances }}</a>
              </li>
              <li>
                <a routerLink="/contact">{{ translations?.contact }}</a>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-md-3 col-sm-6">
          <div class="footer-wid">
            <h3 class="footer-wid-title">{{ translations?.quick_links }}</h3>
            <ul>
              <li>
                <a routerLink="/foundation-tanadgoma">{{
                  translations?.foundation_tanadgoma
                }}</a>
              </li>
              <li>
                <a routerLink="/media/news">{{ translations?.news }}</a>
              </li>
              <li>
                <a routerLink="/medaa/images">{{ translations?.photos }}</a>
              </li>
              <li>
                <a routerLink="/media/videos">{{ translations?.videos }}</a>
              </li>
              <li>
                <a routerLink="/career/hr_policy">{{ translations?.career }}</a>
              </li>
              <li>
                <a routerLink="/site-map">{{ translations?.site_map }}</a>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-md-3 col-sm-6">
          <div class="footer-wid">
            <h3 class="footer-wid-title">{{ translations?.contact_us }}</h3>
            <p>
              <b>{{ translations?.email }}</b
              >: {{ translations?.company_email }}<br />
              <b>{{ translations?.call_us }}</b
              >: {{ translations?.company_phone }}<br />
              {{ translations?.company_phone_2 }}<br />
              <b>{{ translations?.address }}</b
              >: {{ translations?.company_street }},<br />
              {{ translations?.company_city }}
              {{ translations?.company_postal_code }},
              {{ translations?.company_country }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="footer-copyright-area">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          &copy; 2017 Rustavi Azot. All Rights reserved.
        </div>
      </div>
    </div>
  </div>
</footer>
