<!-- Breadcroumbs start -->
<div class="factorian-content-block factorian-breadcroumb section-gray">
    <div class="container">
        <div class="row">
            <div class="col-md-6">
                <h2>{{translations?.site_map}}</h2>
                <a routerLink="/">{{translations?.home}}</a> / {{translations?.site_map}}
            </div>
              
            <div class="col-md-6 text-right">
                <h3></h3>
            </div>
        </div>
    </div>
</div>

<div class="container grid-container">
    <div class="row wow fadeInUp">
     <div class="col-md-6">
        <ul > 
            <li  *ngFor="let item of menu">
                <a routerLink="{{item.link}}" role="menuitem" tabindex="-1" *ngIf="item_children_length(item)==0">
                    {{item.name}}
                </a>
                <div  *ngIf="item_children_length(item)>0">
                    <h4 tabindex="0" >{{item.name}}</h4>
                    
                    <ul >
                        <li *ngFor="let subitem of item.children">
                            <a routerLink="{{subitem.link}}" >
                                {{subitem.name}}
                            </a>
                        </li>
                    </ul>
                  </div>
            </li>
        </ul>     
     </div>
    
   </div>
</div>
   
   
   
   
   
   