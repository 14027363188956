<!-- Breadcroumbs start -->
<div class="factorian-content-block factorian-breadcroumb section-gray">
    <div class="container">
        <div class="row">
            <div class="col-md-6">
                <h2>{{product.name}}</h2>
                <a routerLink="/">{{translations?.home}}</a> / 
                <a routerLink="/products/all">{{translations?.products}}</a>/ {{product.name}}
            </div>
              
            <div class="col-md-6 text-right">
                <h3></h3>
            </div>
        </div>
    </div>
</div>
<!-- Service content start -->
    <div class="factorian-content-block">
        <div class="container">
            <div class="row wow fadeInUp">
                <div class="col-sm-6" id="product_images">
                    <a title="Gallery 1" class="gallery-lightbox" href="/assets/local/product_images/{{product?.id}}_1.jpg">
                      <i class="fa fa-search"></i> <img src="assets/local/product_images/thumbnails/{{product?.id}}_1.jpg" alt="" (load)="images_loaded()" *ngIf="product.id">
                    </a>
                    <div class="row">
                        <div class="col-md-6" *ngFor="let media of product.images">
                            <a title="Gallery 1" class="gallery-lightbox" href="/assets/local/product_images/{{product?.id}}_{{media}}.jpg"><i class="fa fa-search"></i> 
                              <img src="/assets/local/product_images/thumbnails/{{product?.id}}_{{media}}.jpg" alt="" (load)="images_loaded()">
                            </a>
                        </div>
                        
                    </div>
                </div>
                
                <div class="col-sm-6">
                    <div class="spacer-15"></div>
                    <h1>{{product?.name}}</h1>
                    <h3>{{product?.tag}}</h3>
                    <p *ngIf="product?.pdf">
                        <a href="/assets/local/product_pdf_files/{{product?.id}}_{{product?.lang}}.pdf" target="_blank">
                            <button class="download_details">{{translations.download_details}}</button>
                        </a>
                    </p>
                    <p *ngIf="product?.cas_number">
                            <span class="tag">{{translations?.cas_number}} : </span>{{product?.cas_number}}
                        </p>
                    <p *ngIf="product?.formula">
                        <span class="tag">{{translations?.formula}} : </span>{{product?.formula}}
                    </p>
                    <p *ngIf="product?.production_process">
                        <span class="tag">{{translations?.production_process}} : </span>{{product?.production_process}}
                    </p>
                    <p *ngIf="product?.packaging">
                        <span class="tag">{{translations?.packaging}} : </span> {{product?.packaging}}
                    </p>
                    <p *ngIf="product?.usage">
                        <span class="tag">{{translations?.usage}} : </span><span [innerHTML]="product?.usage"></span>
                    </p>
                    <p *ngIf="product?.transportation">
                        <span class="tag">{{translations?.transportation}} : </span>{{product?.transportation}}
                    </p>
                    <p *ngIf="product?.valid_duration">
                            <span class="tag">{{translations?.valid_duration}} : </span>{{product?.valid_duration}}
                        </p>
                    </div>
            </div>
            
            <div class="spacer-45"></div>
        </div>
    </div>
    <!-- Service content end -->     
    <div id="blueimp-gallery" class="blueimp-gallery blueimp-gallery-controls">
    <div class="slides"></div>
    <h3 class="title"></h3>
    <a class="prev">‹</a>
    <a class="next">›</a>
    <a class="close">×</a>
    <a class="play-pause"></a>
    <ol class="indicator"></ol>
</div>