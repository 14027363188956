<!-- Breadcroumbs start -->
<div class="factorian-content-block factorian-breadcroumb section-gray">
    <div class="container">
        <div class="row">
            <div class="col-md-6">
                <h2>Images</h2>
                <a routerLink="/">Home</a> / Images
            </div>
              
            <div class="col-md-6 text-right">
                <h3></h3>
            </div>
        </div>
    </div>
</div>

<div class="container grid-container">
 
 <div class="row">
  <!-- Portfolio Gallery Grid -->
  <div id="grid" class="gallery-isotope grid-4 masonry gutter clearfix portfolio" style="min-height: 400px;">

      <!-- Portfolio Item Start -->
    <div class="gallery-item pipeline" *ngFor="let item of [0,1,2,3,4,5,6, 7, 8, 9 ,10,11, 12, 13, 14, 15]">
      <a href="http://via.placeholder.com/1350x900" class="gallery-lightbox" style="margin-bottom: 0px;"><i class="fa fa-search"></i> 
        <img class="img-fullwidth" src="http://via.placeholder.com/450x300" alt="image" (load)="images_loaded()">
      </a>
    </div>
  <!-- End Portfolio Gallery Grid -->

 </div>
</div>

<div id="blueimp-gallery" class="blueimp-gallery blueimp-gallery-controls">
    <div class="slides"></div>
    <h3 class="title"></h3>
    <a class="prev">‹</a>
    <a class="next">›</a>
    <a class="close">×</a>
    <a class="play-pause"></a>
    <ol class="indicator"></ol>
</div>



