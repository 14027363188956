<!-- Breadcroumbs start -->
<div class="factorian-content-block factorian-breadcroumb section-gray">
        <div class="container">
            <div class="row">
                <div class="col-md-6">
                    <h2>{{translations?.contact}}</h2>
                    <a routerLink="/">{{translations?.home}}</a> / {{translations?.contact}}
                </div>
                  
                <div class="col-md-6 text-right">
                    <h3></h3>
                </div>
            </div>
        </div>
    </div>

<!-- Contact area start -->
    <div class="factorian-content-block">
        <div class="container">
            <div class="row wow fadeInUp">
                <div class="col-md-6">
                    <h1>{{translations.contact_us}}</h1>
                    
                    
                    <div class="row">
                        <div class="col-md-6">
                            <p class="contact-heading">{{translations?.call_us}}</p>
                            <a href="" class="bigger-text-link">{{translations?.company_phone}}</a><br/>
                            <a class="bigger-text-link" href="">{{translations?.company_phone_2}}</a>
                        </div>
                        <div class="col-md-6">
                            <p class="contact-heading">{{translations?.email_us}}</p>
                            <a href="" class="bigger-text-link">{{translations?.company_email}}</a><br/>
                            
                        </div>
                    </div>
                    <p class="contact-heading">{{translations?.address}}</p>
                    <p class="bigger-text-link"> {{translations?.company_country}}, {{translations?.company_city}} {{translations?.company_postal_code}}, <br>{{translations?.company_street}}</p>
                </div>
                
                
                
                <div class="col-md-5 col-md-offset-1">
                    <div class="quote-title">
                        <h3>{{translations?.write_to_us}}</h3>
                        {{translations?.answer_shortly}}
                    </div>
                    
                    <div class="get-quote-form">
                        
                        <form [formGroup]="contact_form" class="form-modern" novalidate #form>
                            <input [(ngModel)]="token" type="hidden" value="" name="csrf_token" formControlName="csrf_token">
                            <div class="form-group form-group-modern">
                                <label for="name">{{translations?.name}}</label> 
                                <input name="name" type="text" class="form-control" formControlName="name">
                                <div class="errorMessage" *ngIf="contact_form.controls.name.errors?.required && tried_to_proceed">
                                    <small>
                                        {{translations?.name_is_required}}
                                    </small>
                                </div>
                            </div>
                            <div class="form-group form-group-modern">
                                <label for="email">{{translations?.email}}</label> 
                                <input name="email" type="email" class="form-control" pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$" formControlName="email">
                                <div class="errorMessage" *ngIf="contact_form.controls.email.errors?.required && tried_to_proceed">
                                    <small>
                                            {{translations?.email_is_required}}
                                    </small>
                                </div>
                                <div class="errorMessage" *ngIf="contact_form.controls.email.errors?.pattern && contact_form.controls.email.dirty && tried_to_proceed">
                                    <small>
                                            {{translations?.email_format_is_wrong}}
                                    </small>
                                </div>
                            </div>
                            <div class="form-group form-group-modern">
                                <label for="phone">{{translations?.phone}}</label> 
                                <input name="phone" type="tel" class="form-control" formControlName="phone">
                            </div>
                            <div class="form-group form-group-modern">
                                <label for="message">{{translations?.message}}</label> 
                                <textarea name="message" id="message" cols="30" rows="10" class="form-control" formControlName="message"></textarea>
                                <div class="errorMessage" *ngIf="contact_form.controls.message.errors?.required && tried_to_proceed">
                                    <small>
                                            {{translations?.message_is_required}}
                                    </small>
                                </div>
                            </div>
                            <div>
                                <input type="submit" value="{{translations?.send}}" (click)="submit_form(contact_form, form)">
                            </div>
                            <h5 id="msg">{{translations?.message_sent}}</h5>
                        </form>
                    </div>
                </div>
            </div>
    </div>
</div>
<div class=" wow fadeInUp">
      <agm-map [latitude]="lat" [longitude]="lng" [zoom]="zoom" [scrollwheel]="scrollWheel">
        <agm-marker [latitude]="lat" [longitude]="lng" [iconUrl]="iconUrl"></agm-marker>
      </agm-map>
</div>
    <!-- contact area end -->    
