<!-- Breadcroumbs start -->
<div class="factorian-content-block factorian-breadcroumb section-gray">
    <div class="container">
        <div class="row">
            <div class="col-md-6">
                <h2>{{translations?.images}}</h2>
                <a routerLink="/">{{translations?.home}}</a> / {{translations?.images}}
            </div>
              
            <div class="col-md-6 text-right">
                <h3></h3>
            </div>
        </div>
    </div>
</div>

<div class="container grid-container">
    <div class="row wow fadeInUp">
      <div class="portfolio-filter font-alt align-center" >
        <div class="col-md-12  filter">
          <div class="col-md-3 text-center" *ngFor="let item of filter">
            <a href="#" class="filter-item {{item.class}}" [attr.data-filter]="item.link">{{item.name}}</a>
          </div>
        </div>
      </div>
    </div>
  <div class="row wow fadeInUp">
     <!-- Portfolio Gallery Grid -->
     <div id="grid" class="gallery-isotope grid-4 masonry gutter clearfix portfolio" style="min-height: 400px;">
   
         <!-- Portfolio Item Start -->
       <div [attr.class]="set_class(item)" *ngFor="let item of images">
         <a href="/assets/local/gallery/{{item.id}}.jpg" [attr.class]="set_class_lightbox(item)" style="margin-bottom: 0px;"><i class="fa fa-search"></i> 
           <img class="img-fullwidth" src="/assets/local/gallery/thumbnails/{{item.id}}.jpg" alt="image" (load)="images_loaded()">
         </a>
       </div>
     <!-- End Portfolio Gallery Grid -->
   
    </div>
   </div>
   
   <div id="blueimp-gallery" class="blueimp-gallery blueimp-gallery-controls">
       <div class="slides"></div>
       <h3 class="title"></h3>
       <a class="prev">‹</a>
       <a class="next">›</a>
       <a class="close">×</a>
       <a class="play-pause"></a>
       <ol class="indicator"></ol>
   </div>
   
   
   
   