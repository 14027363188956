<!-- Breadcroumbs start -->
<div class="factorian-content-block factorian-breadcroumb section-gray">
        <div class="container">
            <div class="row">
                <div class="col-md-6">
                    <h2>{{translations?.blog}}</h2>
                    <a routerLink="/">{{translations?.home}}</a> / {{translations?.blog}}
                </div>
                  
                <div class="col-md-6 text-right">
                    <h3></h3>
                </div>
            </div>
        </div>
    </div>
    <!-- blog content start -->
    <div class="factorian-content-block">
        <div class="container">
            <div class="row wow fadeInUp" *ngIf="filter.length > 1">
                <div class="portfolio-filter font-alt align-center" >
                    <div class="col-md-12 filter">
                        <div class="col-md-4 text-center" *ngFor="let item of filter">
                            <a href="#" class="filter-item {{item.class}}" [attr.data-filter]="item.link">{{item.name}}</a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <div id="grid" class="gallery-isotope grid-4 masonry gutter clearfix portfolio" style="min-height: 400px;">
                        <div [attr.class]="set_class(item)" *ngFor="let item of blogs">
                            <a routerLink="/media/blog/{{item.id}}" class="single-boxed-item wow fadeInUp">
                                <div class="boxed-preview">
                                    <img src="/assets/local/blog_images/thumbnails/{{item.id}}.jpg" alt="" (load)="images_loaded()">
                                </div>
                                <h4>{{item.name}}</h4>
                                {{item.date_full_formatted}}
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>  
    <!-- Blog content end -->   